@layer components {

  .display-1,
  .display-2,
  .display-3,
  .display-4,
  label {
    @apply font-circe-rounded-regular-400;
  }

  .display-1 {
    @apply text-[3rem] leading-[3.75rem] md:text-[4.5rem] md:leading-[5.625rem];
  }

  /* Display 2 Desktop Montserrat Bold / 600 Display 2 Mobile Montserrat Bold / 600 */
  .display-2 {
    @apply text-[1.875rem] leading-[2.375rem] md:text-[3rem] md:leading-[3.75rem];
  }

  /* Display 3 Desktop Montserrat Bold / 600 Display 3 Mobile Montserrat Bold / 600 */
  .display-3 {
    @apply text-[1.5rem] leading-[2rem] md:text-[2.25rem] md:leading-[2.7rem];
  }

  /* Display 4 Desktop Montserrat Bold / 600 Display 3 Mobile Montserrat Bold / 600 */
  .display-4 {
    @apply text-lg leading-6;
  }
}
