@tailwind components;

@layer components {
  .rm-cpm {
    @apply px-4 sm:px-8 lg:px-10 py-10 sm:py-20;
  }

  .rm-bpm {
    @apply py-2 md:py-4 xl:py-6 px-4 md:px-8 xl:px-10;
  }

  .faq {
    ul {
      @apply list-disc ms-8;
    }

    a {
      @apply lg-link;
    }

    .cta {
      @apply text-primary-600 no-underline px-4 py-2 text-base font-bold;
    }
  }

  .legal {
    @apply lg-regular text-gray-600;

    h3,
    h4 {
      @apply text-gray-900 pb-4 px-0;
    }

    p {
      @apply pb-2;
    }

    a {
      @apply text-primary-600 hover:underline break-all;
    }

    ul {
      @apply list-disc list-inside ps-4 py-6;
    }

    ol {
      @apply list-decimal list-inside ps-4 pb-4;
    }

    img {
      @apply mx-auto;
    }

    table {
      @apply table border border-gray-300 w-full my-4;

      td,
      th {
        @apply border py-1 px-4;
      }
    }
  }

  .calculator-slider {
    @apply [&::-moz-range-track]:h-[10px] [&::-moz-range-track]:h-[10px] [&::-moz-range-track]:rounded-full [&::-moz-range-track]:bg-black/25 [&::-moz-range-thumb]:-mt-1 [&::-moz-range-thumb]:h-[20px] [&::-moz-range-thumb]:w-[20px] [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:border-2 [&::-moz-range-thumb]:border-primary-500 [&::-moz-range-thumb]:bg-white [&::-webkit-slider-runnable-track]:h-[10px] [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-black/25 [&::-webkit-slider-thumb]:-mt-1 [&::-webkit-slider-thumb]:h-[20px] [&::-webkit-slider-thumb]:w-[20px] [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:border-2 [&::-webkit-slider-thumb]:border-primary-500 [&::-webkit-slider-thumb]:bg-white;
  }

  .scrollbar {

    /* Define custom scrollbar styles */
    &::-webkit-scrollbar {
      @apply w-0 rounded-r-[0.25rem];
    }

    &::-webkit-scrollbar-track {
      @apply rounded-r-[0.25rem];
    }

    &.width {
      &::-webkit-scrollbar {
        @apply w-2;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-none;
      }
    }

    /* Track */

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply rounded-r-[0.25rem] bg-neutral-300;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      @apply bg-neutral-300;
    }
  }

  // DisputeTransaction

  .rm-dispute {

    // Typography
    h1,
    h2,
    h3,
    h4 {
      @apply font-circe-bold-700;
    }

    h1 {
      @apply text-accent-ink text-[28px] leading-9;
    }

    h2 {
      @apply text-[22px] leading-7;
    }

    h3 {
      @apply text-[20px] leading-7;
    }

    p,
    label,
    span {
      @apply font-circe-regular leading-6;
    }

    .base-text {
      @apply text-base text-gray-500 leading-5;
    }

    .md-small {
      @apply text-base leading-5;
    }

    // Typography Ends

    // Buttons
    .btn {
      &.primary {
        &.disabled {
          @apply bg-primary-300;
        }
      }

      &.upload {
        @apply focus:ring-0;
      }
    }

    rm-container {
      .rm-cpm {
        @apply px-6 py-[0.5rem] sm:py-8 xl:px-10 xs:px-4;
      }
    }

    .display-3 {
      @apply text-[1.5rem] leading-[1.2rem] md:leading-[2.7rem];
    }
  }

  .scrollbar {

    /* Define custom scrollbar styles */
    &::-webkit-scrollbar {
      @apply w-0 rounded-r-[0.25rem];
    }

    &::-webkit-scrollbar-track {
      @apply rounded-r-[0.25rem];
    }

    &.width {
      &::-webkit-scrollbar {
        @apply w-2;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-none;
      }
    }
  }
}
