@layer components {
  .sm-regular,
  .sm-bold,
  .sm-italic,
  .sm-link {
    @apply text-sm font-circe-regular;
  }

  .sm-bold {
    @apply font-bold font-circe-bold-700;
  }

  .sm-italic {
    @apply italic;
  }

  .sm-link {
    @apply text-primary-600 hover:text-primary-700 underline;
  }
}
