@tailwind base;

@layer base {
  @font-face {
    font-family: 'Circe-Font-400';
    src: url('../circle/circle-regular-font.woff2') format('woff2'), url('../circle/circle-regular-font.woff') format('woff'), url('../circle/circle-regular-font.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Circe-Font-Regular';
    src: url('../circle/circle-regular-font.woff2') format('woff2'), url('../circle/circle-regular-font.woff') format('woff'), url('../circle/circle-regular-font.otf') format('otf');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CirceBold-Font-400';
    src: url('../circle/circle-bold-font.woff2') format('woff2'), url('../circle/circle-bold-font.woff') format('woff'), url('../circle/circe-bold-font.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CirceBold-Font-700';
    src: url('../circle/circle-bold-font.woff2') format('woff2'), url('../circle/circle-bold-font.woff') format('woff'), url('../circle/circe-bold-font.otf') format('otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CirceRoundedRegular-Font-400';
    src: url('../circle/circle-round-regular-font.woff2') format('woff2'), url('../circle/circle-round-regular-font.woff') format('woff'), url('../circle/circle-round-font.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CirceRoundedRegular-Font-700';
    src: url('../circle/circle-round-regular-font.woff2') format('woff2'), url('../circle/circle-round-regular-font.woff') format('woff'), url('../circle/circle-round-font.otf') format('otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}
