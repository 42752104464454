@import './assets/scss/common';
@import './assets/scss/button';
@import './assets/scss/forms';
@import '../tailiwndConfig/fonts/scss/fonts';
@import '../tailiwndConfig/typography/typography';

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
