@tailwind components;

@layer components {
  .btn {
    @apply font-bold tracking-wide lowercase first-letter:uppercase lg-regular w-fit rounded-lg px-4 py-2 transition duration-300 ease-in-out focus:outline-none focus:ring-1 active:scale-90;

    &.primary {
      @apply border border-primary-600 bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-400 active:bg-primary-800;

      &.disabled {
        @apply border-gray-300 bg-gray-300 text-white focus:ring-0 active:scale-100;
      }
    }

    &.secondary {
      @apply border border-gray-600 bg-transparent text-gray-600 hover:bg-gray-600 hover:bg-opacity-10 focus:text-base-white focus:ring-primary-400 focus:bg-primary-700 focus:ring-4 focus:ring-offset-gray-100 focus:ring-offset-1;

      &.disabled {
        @apply border-gray-300 bg-transparent text-gray-300 focus:ring-0 active:scale-100;
      }
    }

    &.transparent {
      @apply border border-accent-ink bg-transparent text-accent-ink hover:bg-primary-500 hover:text-accent-paper active:bg-base-white active:text-gray-600;
    }
  }
}