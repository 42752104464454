@tailwind components;

@layer components {
  .form-control {
    @apply mt-2 rounded-lg border border-gray-300 bg-white px-4 leading-10 text-gray-900 md-regular;

    .form-field-box-shadow {
      @apply shadow-form-input;
    }

    &:focus {
      @apply border-primary-600 shadow-form-input;
    }

    &:focus-visible {
      outline: none;
    }

    &.ng-invalid.ng-touched:not(:disabled):not([readonly]) {
      @apply border border-[#d92d20] shadow-form-invalid;
    }

    &:disabled,
    &[readonly] {
      @apply rounded border-gray-400 bg-gray-300 text-black opacity-50 shadow-none;
    }
  }

  .input-with-euro{
    @apply relative h-10 before:content-['£'] before:rounded-l-lg before:absolute before:h-10 before:flex before:items-center before:bg-gray-100 before:px-4 before:left-px before:top-[9px];
  }

  .radio-btn-shadow {
    border: 1px solid white;
  }

  .custom-radio-btn {
    label {
      @apply md-regular secondary flex h-full w-full cursor-pointer hover:bg-primary-600 hover:text-base-white items-center justify-center rounded-lg border border-gray-600 px-4 py-2 text-center
      capitalize text-gray-600 transition duration-300 ease-in-out focus:outline-none focus:ring-1 active:scale-90 sm:block sm:p-2 md:px-4;
    }

    .radio-input:checked + label {
      @apply radio-btn-shadow block w-full cursor-pointer border bg-primary-600 text-center text-base-white;
    }

    &.ng-invalid.ng-touched:not(:disabled):not([readonly]) {
      label {
        @apply md-regular rounded-lg px-4 py-2 capitalize transition duration-300 ease-in-out focus:outline-none focus:ring-1
        active:scale-90 md-regular secondary radio-btn-shadow block w-full cursor-pointer text-center shadow-form-invalid;
        border: 2px solid #cc3b33;
      }
    }
  }

  .warning-border {
    @apply shadow-form-checkbox-warning;
  }

  .form-invalid {
    &.ng-invalid.ng-touched:not(:disabled):not([readonly]) {
      @apply border-2 border-[#cc3b33] shadow-form-invalid;
    }
  }

  .dropdown {
    select {
      @apply right-6 h-10 px-4 w-full bg-arrow bg-right bg-no-repeat bg-origin-content bg-blend-darken bg-[size:0.75rem] appearance-none cursor-pointer;
      option {
        @apply py-2;
      }
    }

    option {
      @apply leading-9;
    }

    ng-select {
      @apply h-10 pe-4 align-middle text-gray-900;
      ng-option {
        @apply -ms-4 w-auto;
      }
      .ng-dropdown-panel {
        @apply -ms-4 w-full rounded-[4px] border bg-white text-black shadow-dropdown-shadow;
      }
    }
  }

  .checkbox-input {
    @apply relative float-left min-h-[20px] min-w-[20px] w-5 h-5 cursor-pointer appearance-none rounded-md border border-solid border-gray-600 bg-white align-top
    transition-all after:absolute after:h-[14px] after:w-[14px] after:inset-0 after:m-auto after:bg-tick after:bg-contain after:bg-center
    after:bg-no-repeat after:bg-origin-content after:opacity-0 after:transition-all checked:border-transparent
    checked:bg-primary-600 checked:after:opacity-100;

    &:focus {
      @apply border-primary-600 shadow-form-input;
    }

    &:not(:checked):focus { 
      @apply shadow-none; 
    }

    &:focus-visible {
      outline: none;
    }

    &.ng-invalid.ng-touched:not(:disabled):not([readonly]) {
      @apply border border-[#d92d20] shadow-form-invalid;
    }
  }

  .ng-option {
    @apply py-1.5 ps-2.5 text-black hover:bg-gray-50;
    &.ng-option-selected {
      @apply bg-gray-100 font-semibold;
    }
  }
}
