@layer components {
  /* Letter Spacing */
  h1,h2,h3,h4,h5,h6 ,.h1,.h2,.h3,.h4,.h5,.h6{
    @apply font-circe-regular
  }
  h1,
  .h1 {
    @apply text-[2.25rem] leading-[2.75rem] md:text-[3.75rem] md:leading-[4.625rem];
  }

  /* Letter Spacing */
  h2,
  .h2 {
    @apply text-[1.875rem] leading-[2.375rem] md:text-[3rem] md:leading-[3.75rem];
  }

  /* Letter Spacing */
  h3,
  .h3 {
    @apply text-[1.5rem] leading-[2rem] md:text-[2.25rem] md:leading-[2.75rem];
  }

  .commonH4H5H6 {
    @apply text-[1.25rem] leading-[1.75rem];
  }

  h4,
  .h4 {
    @apply commonH4H5H6 md:text-[1.875rem] md:leading-[2.375rem];
  }

  h5,
  .h5 {
    @apply commonH4H5H6 md:text-[1.5rem] md:leading-[2rem];
  }

  h6,
  .h6 {
    @apply commonH4H5H6 md:text-[1.25rem] md:leading-[1.75rem];
  }
}
