@layer components {
  .md-regular,
  .md-italic,
  .md-link {
    @apply text-base font-circe-regular;
  }


  .md-bold {
    @apply font-bold font-circe-bold-700;
  }

  .md-italic {
    @apply italic;
  }

  .md-link {
    @apply text-primary-600 hover:text-primary-700 underline;
  }
}
