@layer components {
  .xs-regular,
  .xs-bold,
  .xs-italic,
  .xs-link {
    @apply text-xs leading-[1.125rem] font-circe-regular;
  }

  .xs-bold {
    @apply font-bold font-circe-bold-700;
  }

  .xs-italic {
    @apply italic;
  }

  .xs-link {
    @apply text-primary-600 hover:text-primary-700 underline;
  }
}
