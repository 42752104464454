@layer components {
  .lg-regular,
  .lg-bold,
  .lg-italic,
  .lg-link {
    @apply text-lg leading-[1.625rem] font-circe-regular;
  }

  .lg-bold {
    @apply font-bold font-circe-bold-700;
  }

  .lg-italic {
    @apply italic;
  }

  .lg-link {
    @apply text-primary-600 hover:text-primary-700 underline;
  }
}
